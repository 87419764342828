import React, { useRef } from 'react';
import Seo from '../components/seo';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'reactstrap';
import { Link as ScrollLink} from 'react-scroll';

import award1 from '../images/box/badge_app_essentials.svg';
import award2 from '../images/box/badge_apple_watch.svg';
import award3 from '../images/box/badge_apps_we_love_right_now.svg';
import separator from '../images/box/separator.png';

import Slider from 'react-slick';
import arrow from '../images/black2021/arrow_right.svg';
import adviceIcon from '../images/black2021/advice_icon.svg';
import changeMealIcon from '../images/black2021/change_meal.svg';
import dietitianIcon from '../images/black2021/dietitian_icon.svg';
import dumbbellIcon from '../images/black2021/dumbbell_icon.svg';
import listIcon from '../images/black2021/list_icon.svg';
import measurementIcon from '../images/black2021/measurement_icon.svg';
import menuIcon from '../images/black2021/menu_icon.svg';
import workoutIcon from '../images/black2021/workout_icon.svg';

import mealVideo from '../images/black2021/meal_video.mp4';

import goldArrow from '../images/box/gold_arrow.svg';
import goldArrow2 from '../images/box/gold_arrow2.svg';

import confettiLeft from '../images/box/confetti_left.png';
import confettiRight from '../images/box/confetti_right.png';

import SaleProductPromoBox3 from "../components/SaleProduct/SaleProductPromoBox3";
import SaleProductProvider from "../SaleProduct/SaleProductContext";
import { addToCartLink } from "../SaleProduct/CreateAddToCartLink";

import { Parallax } from 'react-scroll-parallax';

const Page = ({ data }) => {
    const phone = useRef<HTMLDivElement>(null);
    const slider = useRef(null);

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    };

    const handleNext = () => {
        slider.current.slickNext();
    };

    return (
        <Layout page="box" showMenu={true}>
            <Seo
                title="Dożywotni dostęp dla Twoich bliskich - Diet &amp; Training by Ann"
                description="Pyszne dania, satysfakcjonujące treningi i sposób na wyciszenie. Podaruj to wszystko osobom, na którym zależy Ci najbardziej! Liczba dostępów limitowana!"
            />

            <section className="hero">

                <Parallax className="confettiLeft d-none d-md-block" y={[-40, 40]}>
                    <img srcSet={`${confettiLeft} 100w`} sizes="50px" className="" alt="" />
                </Parallax>

                <Parallax className="confettiRight d-none d-md-block" y={[-40, 40]}>
                    <img srcSet={`${confettiRight} 100w`} sizes="50px" className="" alt="" />
                </Parallax>

                <Container>
                    <Row>
                        <Col md="4">
                            <h1 className="z-index-1 mb-2">Dożywotni dostęp!</h1>
                            <h2 className="z-index-1 mb-4">dla Twoich bliskich</h2>
                            <p className="z-index-1">Pyszne dania, satysfakcjonujące treningi i&nbsp;sposób na wyciszenie. Podaruj to wszystko osobom, na którym zależy Ci najbardziej!</p>

                            <ScrollLink
                                    className="btn btn-pink mt-4 z-index-1"
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    Kup dostęp dożywotni
                            </ScrollLink>
                        </Col>
                        <Col md="8">
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage image={data.heroImg.childImageSharp.gatsbyImageData} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="promo-info">
                <Container className="text-center">
                    <Row>
                        <Col xs="6" md="3">
                            <span>6</span>
                            <p>autorskich wersji diety Anny Lewandowskiej</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span>5</span>
                            <p>trenerów dostępnych w aplikacji całą dobę</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span>90+</span>
                            <p>planów treningowych - znajdź swoje ulubione!</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span>3.3 mln</span>
                            <p>
                                użytkowników <br /> aplikacji
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="awards pb-0">
                <Container>
                    <Row>
                        <Col xs="12" md="4">
                            <img
                                src={award1}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="4">
                            <img
                                src={award3}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="4">
                            <img
                                src={award2}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <div className="text-center my-5 py-5">
                            <img src={separator} alt="" className="w-100" />
                        </div>
                    </Row>
                </Container>
            </section>
            <section className="new-functionalities pt-0">
                <Container>
                    <h2 className="text-center mt-md-5">
                        Ulubione <span>nowości</span>
                    </h2>

                    <Row className="align-items-center">
                        <Col md="6">
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.mealDetailsPhone.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <video
                                    className="meal-video"
                                    aria-hidden="true"
                                    muted
                                    autoPlay
                                    playsInline
                                    loop
                                >
                                    <source src={mealVideo} type="video/mp4" />
                                </video>
                            </div>
                        </Col>
                        <Col
                            md="6"
                            className="z-index-1 text-center text-md-left"
                        >
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Twój jadłospis</span>
                                <h3 className="mt-5 mt-md-4">
                                    zdrowo, <br/>
                                    <span>
                                        a smacznie
                                    </span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Z przepisami wideo każde danie będzie jeszcze prostsze w przygotowaniu.
                                </p>
                                <p className="smaller-paragraph">
                                    Z diety w aplikacji możesz korzystać miesiącami i codziennie poznawać zupełnie nowe, inne smaki. Różnorodność to bardzo ważna zasada zdrowej diety, dlatego tylko w tym roku dodaliśmy do aplikacji aż 820 nowych przepisów.
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-5 mt-md-4"
                                >
                                    Kup dostęp dożywotni 
                                </ScrollLink>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-5 mt-5 align-items-center text-center text-md-left">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box mt-5 mt-md-0 pt-4 pt-md-0">
                                <span className="tag">Nowa funkcja</span>
                                <h3 className="mt-5 mt-md-4">
                                    tylko{' '}
                                    <span>
                                        to, <br/>co lubisz
                                    </span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zapisuj przepisy ulubionych posiłków, by móc dodać je do swojego jadłospisu, gdy tylko masz na to ochotę.
                                </p>
                                <p className="smaller-paragraph">
                                    Każdy z nas ma swoje ulubione dania, do których lubi co jakiś czas wrócić. Dzięki funkcji Ulubione posiłki to bardzo proste, bo wybrane przez siebie dania można kolekcjonować w jednym miejscu i mieć do nich jeszcze szybszy, łatwiejszy dostęp. Dzięki temu dieta z aplikacją jest jeszcze prostsza w codziennym stosowaniu!
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-5 mt-md-4"
                                >
                                    Kup dostęp dożywotni
                                </ScrollLink>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box d-flex justify-content-end">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.favoriteMeal.childImageSharp
                                            .gatsbyImageData
                                    }
                                    className="w-90"
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className="animated-slides text-center">
                <GatsbyImage
                    image={data.workouts.childImageSharp.gatsbyImageData}
                    className="workouts-img d-md-none"
                    alt=""
                />
                <Container>
                    <Row>
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 12, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box">
                                <div className="tag d-inline-block">Nowość</div>
                                <h3 className="mt-5 mt-md-4">
                                    90 dopasowanych <br />
                                    <span>planów treningowych</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Jedna aplikacja lub 82 kg płyt DVD z
                                    treningami. Ty wybierasz, który dziś
                                    zaczniesz, a ich liczba regularnie rośnie!
                                </p>
                                <p className="smaller-paragraph">
                                    Dla początkujących i zaawansowanych. Dla kobiet, mężczyzn oraz par. Treningi wzmacniające, na super szybkie spalanie, cardio i tabata. Coś specjalnie dla młodych mam i na każdy trymestr ciąży. Opcje ćwiczeń ze sprzętem lub bez niego. Yoga i treningi łączące fitness ze sztukami walki. 
                                </p>
                                <p className="smaller-paragraph">
                                    Każdy znajdzie tu coś dla siebie!
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            className="d-none d-md-block"
                        >
                            <GatsbyImage
                                image={
                                    data.workouts.childImageSharp
                                        .gatsbyImageData
                                }
                                className="workouts-img"
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <div className="slideshow">
                    <div className="line1">
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                    <div className="line2">
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                </div>
            </section>

            <section className="new-functionalities text-center text-md-left pt-4 pt-md-3">
                <Parallax className="confettiRight d-none d-md-block" y={[-40, 40]}>
                    <img srcSet={`${confettiRight} 100w`} sizes="50px" className="" alt="" />
                </Parallax>
                <Container>
                    <h2 className="text-center mt-md-5">
                        DOBRE <span>Z NATURY</span>
                    </h2>
                    <Row className="align-items-center low-energy">
                        <Col md={{ size: 6}}>
                        <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage image={data.aniaEnergy.childImageSharp.gatsbyImageData} alt="" />
                            </div>
                        </Col>
                        <Col md={{ size: 6 }} className="z-index-1">
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">To nasz obowiązek</span>
                                <h3 className="mt-5 mt-md-4">
                                    mniej <br/>
                                    <span>znaczy więcej</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zasilanie serwerów w 100% z odnawialnych źródeł energii to dla nas za mało.
                                </p>
                                <p className="smaller-paragraph">
                                    Programiści w moim teamie dokładają wszelkich starań, by aplikacja zużywała jak najmniej Twojej baterii. Dzięki temu, 30-minutowa sesja treningowa, pochłania o 37% energii mniej, niż na początku tego roku!
                                    Zmiana sposobu dopasowywanie planów jadłospisów obciąża procesor serwera o 20% mniej. To mniejsze zużycia prądu o kilkaset watów każdego dnia!
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-3"
                                >
                                    Kup dostęp dożywotni   
                                </ScrollLink>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row className="pt-5 mt-5 align-items-center tree">
                        <Col xs={{size: 12, order: 2}} md={{size: 5, order: 1}}>
                            <div className="description-box mt-5 mt-md-0 z-index-1">
                                <span className="tag">Ziemia lubi to!</span>
                                <h3 className="mt-5 mt-md-4">
                                    Każdy box<br/>
                                    <span>= posadzone drzewo</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Wszystko w trosce o Ziemię dla nas i&nbsp;naszych dzieci
                                </p>
                                <p className="smaller-paragraph">
                                    Prezent, który podarujesz bliskiemu, to coś więcej, niż dożywotni dostęp do aplikacji. Gdy Ty kupujesz lifetime, my sadzimy nowe drzewo.
                                </p>
                                <p className="smaller-paragraph">
                                    Dodatkowo dostęp przekażemy w opakowaniu wykonanym z masy papierowej, certyfikowanej przez Forest Stewardship Council (FSC), zawierającej materiał drzewny z odpowiedzialnie zarządzanych lasów i tartaków. Dzięki nadrukowi farbami na bazie wody to opakowanie zostanie łatwo przetworzone.
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-5 mt-md-4"
                                >
                                    Kup dostęp dożywotni
                                </ScrollLink>
                            </div>
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 7, order: 2}}>
                            <div className="box" ref={phone}>
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={data.tree.childImageSharp.gatsbyImageData}
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="motivation text-center text-md-left">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="mt-5 mt-md-0">
                                <span className="tag">Wiedza i motywacja</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>motywacja</span>. twój klucz do
                                    sukcesu
                                </h3>
                                <p>
                                    Unikalne autorskie porady dotyczące diety, treningu i motywacji!
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.feedScreen.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="breathing">
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12" md="6">
                            <GatsbyImage
                                image={
                                    data.breathingIcons.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="6">
                            <div className="description-box text-center text-md-left mt-5 mt-md-0">
                                <span className="tag">HIT</span>
                                <h3 className="mt-5 mt-md-4">
                                    Dobrze wiedzieć, <br />
                                    <span>jak się dobrze czuć</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Opanuj sztukę świadomego oddechu i przekonaj się, ile dzięki temu zyskasz
                                </p>
                                <p className="smaller-paragraph">
                                    Oddech to jedno z najlepszych narzędzi do walki ze stresem: pomaga się uspokoić, skupić na chwili bieżącej lub opanować emocje. Właśnie dlatego oddech warto ćwiczyć i pracować nad tym, żeby był jak najbardziej świadomy. 
                                </p>
                                <p className="smaller-paragraph">
                                    W aplikacji znajdziesz 3 zestawy ćwiczeń, które Ci w tym pomogą: Skupienie, Spokój i Harmonia.
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-5 mt-md-4"
                                >
                                    Kup dostęp dożywotni
                                </ScrollLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="balance text-center text-md-left pt-md-0">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Ulubiona funkcja</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>balans</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zdystansuj się od codziennych spraw i znajdź
                                    wewnętrzny spokój.
                                </p>
                                <p className="smaller-paragraph">
                                    Balans to wyjątkowe miejsce w aplikacji Diet
                                    &amp; Training by Ann wypełnione nagraniami
                                    prosto ze świata przyrody oraz autorską,
                                    relaksacyjną muzyką. Dzięki temu w dowolnym
                                    momencie dnia możesz poczuć się tak, jakbyś
                                    właśnie spacerowała po lesie, siedziała przy
                                    ognisku lub była nad brzegiem morza.
                                    Przygotowaliśmy dla Ciebie aż 20+
                                    wyjątkowych ścieżek!
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-5 mt-md-4"
                                >
                                     Kup dostęp dożywotni
                                </ScrollLink>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.balanceIcons.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="added p-md-0 text-center text-md-left pb-0">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="d-none d-md-block"
                        >
                            <GatsbyImage
                                image={
                                    data.phone.childImageSharp.gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="description-box">
                                <span className="tag">Dodaliśmy</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>
                                        820 nowych <br /> posiłków.{' '}
                                    </span>
                                    spróbujesz wszystkich?
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Jedz tylko to, na co naprawdę masz ochotę. Wybór zawsze należy do Ciebie. 
                                </p>
                                <p className="smaller-paragraph">
                                    Poznaj nowe, oryginalne smaku i przekonaj się jak smakują tradycyjne potrawy w nieco innych, zdrowszych wersjach. Przekonaj się, że zdrowe odżywianie to niekończąca się, pyszna przygoda. Tylko w tym roku dodaliśmy do aplikacji aż 820 nowych przepisów. 
                                </p>
                                <ScrollLink
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                    className="btn btn-pink mt-3 mb-5 mb-mb-0"
                                >
                                    Kup dostęp dożywotni
                                </ScrollLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <GatsbyImage
                    image={data.phone.childImageSharp.gatsbyImageData}
                    alt=""
                    className="d-md-none"
                />
            </section>
            <section className="trainers-slider">
                <Container>
                    <h2 className="text-center">
                        twoi <span>trenerzy</span>
                    </h2>

                    <div className="slider-container">
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.ania.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Treningi funkcjonalne</p>
                                            <h4 className="mt-2">
                                                Anna Lewandowska
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Wielokrotna medalistka mistrzostw
                                        świata, Europy i Polski w karate
                                        tradycyjnym. Była reprezentantka Polski
                                        w karate. Dyplomowana trenerka fitness i
                                        TRX. Z wykształcenia manager sportowy i
                                        dietetyk.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.joanna.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>
                                                Treningi z elementami sztuk
                                                walki
                                            </p>
                                            <h4 className="mt-2">
                                                Joanna Jędrzejczyk
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Reprezentantka Polski w boksie,
                                        kick-boxingu, muay thai oraz mieszanych
                                        sztukach walki (MMA). Trzykrotna
                                        zawodowa mistrzyni świata. Pierwsza
                                        Polka w prestiżowej organizacji Ultimate
                                        Fighting Championship.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.mariola.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />

                                        <div className="trainer-info">
                                            <p>Treningi dla dojrzałych</p>
                                            <h4 className="mt-2">
                                                Mariola Bojarska - Ferenc
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Absolwentka Akademii Wychowania
                                        Fizycznego w Warszawie. Była członkini
                                        kadry narodowej w gimnastyce
                                        artystycznej. Instruktorka fitness z
                                        wieloletnim doświadczeniem, członkini
                                        najważniejszych światowych organizacji
                                        fitness. Swoje treningi kieruje przede
                                        wszystkim do osób dojrzałych.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.ewelina.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Yoga</p>
                                            <h4 className="mt-2">
                                                Ewelina Godlewska
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Dyplomowana nauczycielka yogi z ponad
                                        10-letnim doświadczeniem. Z
                                        wykształcenia psycholog i instruktor
                                        rekreacji ruchowej o specjalności
                                        ćwiczenia psychofizyczne w oparciu o
                                        system hatha yogi.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.kuba.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Treningi dla mężczyzn i par</p>
                                            <h4 className="mt-2">Jakub Głąb</h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Mistrz Polski w Fitness Sylwetkowym,
                                        wielokrotny Mistrz Polski, Europy i
                                        Świata w Karate Tradycyjnym. Z zawodu
                                        trener personalny dietetyk, instruktor
                                        samoobrony i fitness, certyfikowany
                                        trener TRX.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                        <button
                            className="slider-btn prev"
                            onClick={handlePrev}
                        >
                            <img
                                src={arrow}
                                className="rotate180deg"
                                alt="wstecz"
                            />
                        </button>
                        <button
                            className="slider-btn next"
                            onClick={handleNext}
                        >
                            <img src={arrow} alt="dalej" />
                        </button>
                    </div>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <ScrollLink
                                to="buy"
                                smooth={true}
                                duration={1500}
                                className="btn btn-pink d-block mx-auto"
                            >
                                Kup dostęp dożywotni
                                
                            </ScrollLink>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="your-application">
                <Container>
                    <h2 className="text-center">
                        twoja <span>aplikacja</span>
                    </h2>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 6, order: 2 }}
                            md={{ size: 3, order: 1 }}
                            className="mt-5 mt-md-0"
                        >
                            <div className="text-center mb-4">
                                <img src={changeMealIcon} alt="" />
                                <p>
                                    Wymiana <br /> składników
                                </p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={listIcon} alt="" />
                                <p>Interaktywna lista zakupów</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={dietitianIcon} alt="" />
                                <p>Opieka dietetyków i trenerów online</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={menuIcon} alt="" />
                                <p>
                                    Jadłospisy
                                    <br />
                                    okolicznościowe
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="position-relative text-center">
                                <div className="circle">
                                    <div className="circle-gradient" />
                                </div>
                                <GatsbyImage
                                    image={
                                        data.dietTraining.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 6, order: 3 }}
                            md={{ size: 3, order: 3 }}
                            className="mt-5 mt-md-0"
                        >
                            <div className="text-center mb-4">
                                <img src={workoutIcon} alt="" />
                                <p>Ponad 4500 dni treningowych</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={measurementIcon} alt="" />
                                <p>Pomiary i śledzenie postępów</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={adviceIcon} alt="" />
                                <p>Rady i przypomnienia o treningach</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={dumbbellIcon} alt="" />
                                <p>Możliwości ćwiczenia ze sprzętem lub bez</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="new-features">
                <Container>
                    <div className="promo-box">
                        <h3>Przygotowuję już <span>nowe funkcje</span> dla Ciebie</h3>
                        <p>Nieustannie pracuję nad nowymi planami treningowymi i&nbsp;funkcjami w aplikacji. Lifetime to Twoja gwarancja dostępu do wszystkich nowych funkcji, planów diety i&nbsp;treningów!</p>
                        <p>Nowe plany treningowe już wkrótce!</p>
                    </div>
                </Container>
            </section>


            <section className="product pt-0" id="buy">
                <Container>
                    <h2 className="text-center mb-5 z-index-1">EDYCJA <span>LIMITOWANA</span></h2>

                    <Row className="align-items-center">
                        <Col md="6">
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage image={data.voucher.childImageSharp.gatsbyImageData} alt="" />
                            </div>
                        </Col>
                        <Col md={{size: 5, offset: 1}}>
                            <div className="description-box">
                                <h3>Dożywotni dostęp</h3>
                                <p className="smaller-paragraph mb-5"> Dożywotni dostęp do planu diety, a także wszystkich obecnych i nowych zestawów treningowych.</p>
                            </div>
                            
                            <SaleProductProvider tag='landing:box' source='lifetime'>
                                <SaleProductPromoBox3 showOldPrice={true} />
                            </SaleProductProvider>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="how-to-buy pt-0">
                <Container>
                    <h2 className="text-center mb-5">Jak kupić <span>dożywotni dostęp<br/>na raty</span>? To bardzo proste!</h2>
                    <Row>
                        <Col className="d-flex align-items-center d-md-block mb-4 mb-md-0" md="3">
                            <div className="circle">
                                <h2 className="gradient-text z-index-1">01</h2>
                            </div>
                            <p>Kliknij przycisk “Kup&nbsp;teraz”</p>
                            <img className="arrow" src={goldArrow} alt="" />
                        </Col>
                        <Col className="d-flex align-items-center d-md-block mb-4 mb-md-0" md="3">
                            <div className="circle">
                                <h2 className="gradient-text z-index-1">02</h2>
                            </div>
                            <p>Jako formę płatności wybierz PayU / płatność online</p>
                            <img className="arrow mt-4" src={goldArrow2} alt="" />
                        </Col>
                        <Col className="d-flex align-items-center d-md-block mb-4 mb-md-0" md="3"> 
                            <div className="circle">
                                <h2 className="gradient-text z-index-1">03</h2>
                            </div>
                            <p>Na stronie PayU wybierz płatność “Raty”</p>
                            <img className="arrow" src={goldArrow} alt="" />
                        </Col>
                        <Col className="d-flex align-items-center d-md-block mb-4 mb-md-0" md="3">
                            <div className="circle">
                                <h2 className="gradient-text z-index-1">04</h2>
                            </div>
                            <p>Kliknij przycisk "Przejdź do formularza ratalnego" i&nbsp;uzupełnij dane, o&nbsp;które prosi PayU.</p>
                        </Col>
                    </Row>
                    <a
                        href={addToCartLink(377, null, 'lifetime')}
                        className="btn btn-pink mt-4"
                    >
                        Kup teraz
                    </a>
                </Container>
            </section>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query {
        heroImg: file(relativePath: { eq: "box/hero.png" }) {
            ...fluidImage1920
        }

        hero: file(relativePath: { eq: "black2021/hero-img.jpg" }) {
            ...fluidBackground
        }
        heroMobile: file(
            relativePath: { eq: "black2021/first_screen_bg_mob.jpg" }
        ) {
            ...fluidBackground
        }
        trainers: file(relativePath: { eq: "black2021/trainers_hpba.png" }) {
            ...fluidImage1920
        }
        trainersMob: file(
            relativePath: { eq: "black2021/trainers_hpba_mob.png" }
        ) {
            ...fluidImage1920
        }
        mealDetails: file(relativePath: { eq: "black2021/strong.png" }) {
            ...fluidImage1920
        }
        gymHome: file(relativePath: { eq: "black2021/home_gym_bg.jpg" }) {
            ...fluidBackground
        }
        gymHomeMobile: file(
            relativePath: { eq: "black2021/home_gym_bg_mobile.jpg" }
        ) {
            ...fluidBackground
        }

        workouts: file(relativePath: { eq: "box/workouts_screen_blue.png" }) {
            ...fluidImage1920
        }
        programsA: file(
            relativePath: { eq: "black2021/training_programs_1.png" }
        ) {
            ...fluidImage1920
        }
        programsB: file(
            relativePath: { eq: "black2021/training_programs_2.png" }
        ) {
            ...fluidImage1920
        }
        notification: file(
            relativePath: { eq: "black2021/notification_screen.png" }
        ) {
            ...fluidImage1920
        }
        feedScreen: file(relativePath: { eq: "box/feed_screen.png" }) {
            ...fluidImage1920
        }
        breathingIcons: file(
            relativePath: { eq: "black2021/breathing_icons.png" }
        ) {
            ...fluidImage1920
        }
        balanceIcons: file(
            relativePath: { eq: "black2021/balance_icons.png" }
        ) {
            ...fluidImage1920
        }
        phone: file(relativePath: { eq: "black2021/diet_img_bg.jpg" }) {
            ...fluidImage1920
        }
        ania: file(relativePath: { eq: "box/ania.png" }) {
            ...fluidImage1920
        }
        ewelina: file(relativePath: { eq: "box/ewelina.png" }) {
            ...fluidImage1920
        }
        mariola: file(relativePath: { eq: "box/mariola.png" }) {
            ...fluidImage1920
        }
        kuba: file(relativePath: { eq: "box/kuba.png" }) {
            ...fluidImage1920
        }
        joanna: file(relativePath: { eq: "box/joanna.png" }) {
            ...fluidImage1920
        }
        dietTraining: file(
            relativePath: { eq: "black2021/diet_and_training.png" }
        ) {
            ...fluidImage1920
        }
        favoriteMeal: file(
            relativePath: { eq: "box/favorite_meal_screen.png" }
        ) {
            ...fluidImage1920
        }
        mealDetailsPhone: file(
            relativePath: { eq: "black2021/meal_details_phone.png" }
        ) {
            ...fluidImage1920
        }

        aniaEnergy: file(
            relativePath: { eq: "box/ania_energy.png" }
        ) {
            ...fluidImage1920
        }

        tree: file(
            relativePath: { eq: "box/tree_and_present.png" }
        ) {
            ...fluidImage1920
        }

        voucher: file(
            relativePath: { eq: "box/voucher.png" }
        ) {
            ...fluidImage1920
        }
    }
`;
