import React, { Component } from "react";
import { SaleProductConsumer } from "../../SaleProduct/SaleProductContext";
import SaleProductPlaceholderSection from "./SaleProductsPlaceholder";
import { addToCartLink } from "../../SaleProduct/CreateAddToCartLink";

export default class SaleProductPromoBox extends Component {
    render() {
        return (
            <SaleProductConsumer>
                {
                    ({ saleProducts, isLoading, promoCode, source }) =>
                    isLoading ? (
                        <>
                            <SaleProductPlaceholderSection />
                        </>
                    ) : (
                        saleProducts.map(saleProduct => (
                            <div className="promo-box" key={saleProduct.id}>
                                <p>Oferta dla Ciebie</p>
                                
                                {
                                    saleProduct.hasActivePromo && this.props.showOldPrice ? (
                                        <React.Fragment>
                                            <span
                                                className="old-price"
                                            >
                                                {`${saleProduct.regularPrice.toString()}`}
                                            </span>
                                            <span
                                                data-test="product-price"
                                                className="product-price"
                                            >
                                                {`${saleProduct.price.toString()}`}
                                            </span>
                                        </React.Fragment>
                                    ) : (
                                        <span
                                            data-test="product-price"
                                            className="product-price"
                                        >
                                            {`${saleProduct.price.toString()}`}
                                        </span>
                                    )
                                }
                                   
                                    
                                <a
                                    data-testid="add-to-cart"
                                    href={addToCartLink(saleProduct.id, promoCode, source)}
                                    className="btn btn-pink mt-5"
                                >
                                    Kup teraz
                                </a>
                            </div>
                        ))               
                    )
                }
            </SaleProductConsumer>
        );
    }
}
